.headerClass {
  background-color: #f0f7f7;
  /* padding: 15px; */
  box-shadow: 0px 2px 3px #0000001a;
  font-size: 18px;
  font-family: ProximaNovaS;
  z-index: 99;
  padding: 0 25px;
  height: 60px;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 101;
}
