.logout_popup .MuiTypography-h6 {
  font-family: ProximaNovaB !important;
}
.logout_popup .MuiDialogContentText-root {
  font-family: ProximaNovaR;
  color: #000;
}
/* .logout_popup .MuiDialogActions-root {
    display: flex;
    justify-content: space-around;
} */
.logout_popup .MuiDialog-paperScrollPaper {
  border-radius: 8px;
  width: 75%;
  background-color: white;
  /* padding: 15px; */
}
.cancelbutton {
  font-family: ProximaNovaB !important;
  border: 1px solid #00716e !important;
  width: 90px !important;
  height: 35px;
  color: #00716e !important;
}
.logoutbutton {
  font-family: ProximaNovaB !important;
  color: white;
}
.MuiDialog-paper {
  margin: 0px !important;
}
.modalfooter {
  background-color: #edf3f3;
  padding: 12px !important;
  /* margin-top: 50px; */
}
.logoutborder {
  width: 99%;
  margin-top: -10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 60px;
}
