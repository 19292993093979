@font-face {
  font-family: ProximaNovaB;
  src: url('./Fonts/ProximaNova-Bold.ttf');
}
@font-face {
  font-family: ProximaNovaM;
  src: url('./Fonts/ProductSans-Medium.ttf');
}
@font-face {
  font-family: ProximaNovaR;
  src: url('./Fonts/ProximaNova-Regular.ttf');
}
@font-face {
  font-family: ProximaNovaS;
  src: url('./Fonts/ProximaNova-Semibold.ttf');
}
@font-face {
  font-family: ProximaNovaL;
  src: url('./Fonts/ProximaNova-Light.ttf');
}

html,
* {
  -ms-overflow-style: none; /* IE and Edge */
  /* scrollbar-width: none; Firefox */
}

body {
  background-color: #e2eaea !important;
  margin: 0px;
  font-family: ProximaNovaR;
  box-sizing: border-box;
}
input:disabled {
  background: #eeeeee;
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
  color: #048c88;
}

::-webkit-scrollbar {
  width: 0;
}
.Pane1::-webkit-scrollbar,
.Pane2::-webkit-scrollbar {
  height: 0;
}
.Pane1::-webkit-scrollbar,
.Pane2::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.Pane1::-webkit-scrollbar-track,
.Pane2::-webkit-scrollbar-track {
  background-color: transparent;
}
.Pane1::-webkit-scrollbar-thumb,
.Pane2::-webkit-scrollbar-thumb {
  background-color: #00716e;
  border-radius: 4px;
}
/* Queue style */
.delete-icon,
.delete-icon-disabled {
  background-image: url(./Images/delete.svg);
  /* align-self: center; */
  height: 20px;
  width: 31px;
  background-repeat: no-repeat;
  /* background-size: contain; */
  /* display: flex; */
  /* margin: auto;   */
  cursor: pointer;
  margin-left: 13px;
}
.delete-icon-disabled {
  background-image: url(./Images/delete-disabled.svg);
}
/* Queue style */
/* width */
.pageActionContainer::-webkit-scrollbar,
.textfield__content::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
.pageActionContainer::-webkit-scrollbar-track,
.textfield__content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.pageActionContainer::-webkit-scrollbar-thumb,
.textfield__content::-webkit-scrollbar-thumb {
  background: #048c88;
  border-radius: 8px;
}

/* Handle on hover */
.pageActionContainer::-webkit-scrollbar-thumb:hover,
.textfield__content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.regularFont {
  font-size: 18px;
  font-family: ProximaNovaR;
  color: #48454a;
}
.MuiTableRow-head {
  background-color: #ffffff !important;
}
.blue {
  font-family: ProximaNovaS !important;
  color: #048c88;
  display: block;
  text-align: left;
  cursor: pointer;
}
/* React Split Pane Styles */
.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  background: url(../src/Images/DraggerImage.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  background-size: fill;
  border-top: 4px solid rgba(255, 255, 255, 0);
  border-bottom: 4px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
}

/* .Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
} */

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

/* .Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
} */
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}
/* (../src/Images/DraggerImage.svg); */

/* * fixed header and footer for dialog component (MUI) */
.MuiDialogTitle-root,
.MuiDialogActions-root {
  position: sticky;
  top: 0;
  background: white;
}
.MuiDialogActions-root {
  bottom: 0;
}

#myLink {
  position: absolute;
  transform: translateY(-50vh);
}

#imageLayerId {
  border: solid 4px green;
}

/* MagicGridNew Css*/

#imageLayerId {
  border: solid 4px green;
}

.magic-grid {
  border: 2px solid #048c87;
  position: absolute;
  z-index: 999;
  cursor: pointer;
}

.magic-grid-top-control {
  height: 10px;
  width: 100%;
  background: #15c249;
  top: -14px;
  position: absolute;
}

.magic-grid-left-control {
  height: 100%;
  width: 10px;
  background: #15c249;
  left: -14px;
  position: absolute;
}

.BOTTOMRIGHTCorner {
  bottom: -4px;
  right: -4px;
  cursor: nwse-resize;
  position: absolute;
  width: 20px;
  height: 20px;
}

.BOTTOMLEFTCorner {
  bottom: -4px;
  left: -4px;
  cursor: nesw-resize;
  position: absolute;
  width: 20px;
  height: 20px;
}

.TOPLEFTCorner {
  top: -4px;
  left: -4px;
  cursor: nwse-resize;
  position: absolute;
  width: 20px;
  height: 20px;
}

.TOPRIGHTCorner {
  top: -4px;
  right: -4px;
  cursor: nesw-resize;
  position: absolute;
  width: 20px;
  height: 20px;
}

.vertical-line {
  position: absolute;
  width: 2px;
  height: 100%;
  z-index: 9999;
  border: dashed 1px #04716d;
}

.vertical-line.first-line {
  border: none;
}

.vertical-line:hover {
  border: solid 2px #04716d;
  cursor: ew-resize;
}

.vertical-line.first-line:hover {
  border: none;
  cursor: pointer;
}

.horizontal-line {
  position: absolute;
  width: 100%;
  height: 2px;
  z-index: 9999;
  border: dashed 1px #04716d;
}

.horizontal-line.first-line {
  border: none;
}

.horizontal-line:hover {
  border: solid 2px #04716d;
  cursor: ns-resize;
}

.horizontal-line.first-line:hover {
  border: none;
  cursor: pointer;
}

.toBeDeleted-line {
  display: none;
}

.add-column-icon {
  height: 18px;
  width: 17px;
  position: absolute;
  top: -5px;
  color: green;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  pointer-events: none;
  border-radius: 50%;
  z-index: 2;
}

.temp-vertical-line {
  height: 100%;
  width: 2px;
  background: #04716d;
  position: absolute;
  top: 12px;
  z-index: 1;
}

.add-row-icon {
  height: 18px;
  width: 17px;
  position: absolute;
  left: -5px;
  color: green;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  pointer-events: none;
  border-radius: 50%;
}

.temp-horizonatal-line {
  height: 2px;
  width: 100%;
  background: #04716d;
  position: absolute;
  left: 12px;
}

.magic-grid-tick {
  position: absolute;
  background-color: white;
}

.magic-grid-tick:hover {
  background-color: red;
}

.horizontal-bar-line {
  height: 6px;
  top: 2px;
  width: 1px;
}

.vertical-bar-line {
  height: 1px;
  left: 2px;
  width: 6px;
}
.horizontal-bar-line:hover {
  width: 8px;
}
.vertical-bar-line:hover {
  height: 8px;
}

.delete-vertical-line {
  height: 100%;
  width: 2px;
  background: red;
  position: absolute;
  top: 12px;
}

.delete-column-icon {
  height: 18px;
  width: 17px;
  position: absolute;
  top: -5px;
  color: red;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  border-radius: 50%;
  pointer-events: none;
  z-index: 2;
}

.delete-row-icon {
  height: 18px;
  width: 17px;
  position: absolute;
  left: -5px;
  color: red;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  border-radius: 50%;
  pointer-events: none;
  z-index: 2;
}

.delete-horizontal-line {
  height: 2px;
  width: 100%;
  background: red;
  position: absolute;
  left: 12px;
}

.magic-grid-header-icon {
  height: 18px;
  width: 18px;
  position: absolute;
  bottom: 1px;
  left: 0px;
  cursor: pointer;
}

.active-funnel {
  background-image: url(./Images/active-funnel.svg);
}

.funnel {
  background-image: url(./Images/funnel.svg);
}

.magic-grid-header {
  height: 30px;
  width: 100%;
  background: #048c87;
  top: -46px;
  position: absolute;
  border-radius: 8px;
}
.magic-grid-header-value {
  position: absolute;
  color: white;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 20px;
  height: 100%;
  user-select: none;
  cursor: auto;
  font-size: 16px;
  line-height: 30px;
  font-weight: 600;
}

.mandatory-header {
  border-top: solid 2px #15c249;
  margin-top: -3px;
  padding-top: 2px;
  height: 33px;
}
.magic-grid-header-value.active-header {
  background: #00716f;
}

.magic-grid-header-overlay {
  position: absolute;
  top: 30px;
  z-index: 999999;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  padding: 0px;
  display: flex;
  flex-direction: column;
  background-color: #48454a;
}
.magic-grid-header-overlay-item {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  color: white;
  font-size: 14px;
  height: 28px;
  align-items: center;
}

.magic-grid-header-overlay-item:hover {
  background: #048c87;
  color: white;
}

.magic-grid-header-overlay-item-remove {
  background-repeat: no-repeat;
  margin-left: 8px;
  height: 16px;
  width: 14px;
  background-image: url('./Images/plus-white.svg');
}

.magic-grid-options {
  background-color: #058c87;
  padding: 7px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 50%;
  transition: all 0.3s ease;
  margin-right: 10px;
  position: absolute;
  top: -40px;
  left: -30px;
}
.magic-grid-options-icon-down {
  cursor: pointer;
  background-image: url(./Images/DownArrow.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 14px;
  height: 14px;
}

.magic-grid-options-icon-up {
  cursor: pointer;
  background-image: url(./Images/uppperarrow.svg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 14px;
  height: 14px;
}

.magic-grid-options-overlay {
  position: absolute;
  top: 30px;
  z-index: 999999;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  padding: 0px;
  display: flex;
  flex-direction: column;
  background-color: #48454a;
}
.magic-grid-options-overlay-item {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  color: white;
  font-size: 14px;
  height: 28px;
  align-items: center;
}

.magic-grid-options-overlay-item:hover {
  background: #048c87;
  color: white;
}

/* use magic grid */
.magicGridIconContainer,
.magicGridIconContainerDisabled {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #00716f;
  margin-left: 2rem;
  /* width: 150px; */
  color: white;
  padding: 0.4rem 1rem;
  border-radius: 4px;
  cursor: pointer;
}
.magicGridIcon {
  background-image: url('./Images/magic-grid-icon.svg');
  width: 18px;
  height: 18px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 0.25rem;
}
.magicGridIconContainerDisabled {
  background-color: #777777;
  color: black;
}
