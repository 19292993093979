/* @font-face {
  font-family: ProximaNova;
  src: url(../../Fonts/ProximaNova-Bold.ttf);
} */

#sidebar {
  min-width: 190px;
  max-width: 190px;
  width: 60px;
  height: 100vh;
  box-shadow: 0 0 20px 0 rgba(8, 9, 9, 0.15);
  background-color: #048c88;
  color: #fff;
  transition: all 0.3s;
  position: fixed;
}

#sidebar.activexyz {
  /* transition: 0.3s; */
  min-width: 60px;
  max-width: 60px;
  text-align: center;
  background: #00716e;
}

#sidebar.activexyz p {
  display: none;
}
.activepage {
  background-color: #0f5755 !important;
  cursor: pointer !important;
  border-left: solid 3px #48c8c5 !important;
}

ul {
  list-style-type: none;
  margin: 0px !important;
  padding: 0px !important;
}

#sideNavTop {
  margin-top: 15px !important;
  /* position: fixed; */
}

#sideNavTop #logoLi {
  margin-bottom: 20px !important;
}

#sideElements,
#sideElements1,
#sideElements2,
#sideElements3,
#sideElements4 {
  padding: 0px;
  width: inherit;
  height: 60px;
  border-radius: 0px;
}

#sidebar.activexyz #sideElements:hover {
  background-color: #0f5755;
  cursor: pointer;
  border-left: solid 3px #48c8c5;
}
#sidebar.activexyz #sideElements1:hover {
  background-color: #1c8d89;
  cursor: pointer;
  border-left: solid 3px #48c8c5;
}
#sidebar.activexyz #sideElements2:hover {
  background-color: #1c8d89;
  cursor: pointer;
  border-left: solid 3px #48c8c5;
}
#sidebar.activexyz #sideElements3:hover {
  background-color: #1c8d89;
  cursor: pointer;
  border-left: solid 3px #48c8c5;
}
#sidebar.activexyz #sideElements4:hover {
  background-color: #1c8d89;
  cursor: pointer;
  border-left: solid 3px #48c8c5;
}

#sidebar.activexyz .backColor {
  background-color: #0f5755;
  cursor: pointer;
  border-left: solid 3px #48c8c5;
}

#sideElements p,
#sideElements1 p,
#sideElements2 p,
#sideElements3 p,
#sideElements4 p {
  font-size: 14px;
  color: white;
  padding-left: 10px;
  padding-top: 2px;
  font-family: ProximaNovaR;
  text-align: left;
  margin-left: 25px;
}

#sidebar.activexyz .logo_ind {
  background-image: url(../../Images/IN-D-FS-AppLogo.png);
  background-repeat: no-repeat;
  width: 50px;
  height: 43px;
  margin: 0px 3px !important;
  display: inline-block;
}

#sidebar.activexyz .Queue {
  background-image: url(../../Images/Queue1.svg);
  background-repeat: no-repeat;
  margin: 15px !important;
  display: inline-block;
  width: 25px;
  height: 25px;
  background-size: cover;
}

#sidebar.activexyz .Processor {
  background-image: url(../../Images/Processor.svg);
  background-repeat: no-repeat;
  margin: 15px !important;
  display: inline-block;
  width: 25px;
  height: 25px;
  background-size: cover;
}
#sidebar.activexyz .Document {
  background-image: url(../../Images/Document.svg);
  background-repeat: no-repeat;
  margin: 15px !important;
  display: inline-block;
  width: 20px;
  height: 25px;
  background-size: cover;
}
#sidebar.activexyz .Fields {
  background-image: url(../../Images/Fields1.svg);
  background-repeat: no-repeat;
  margin: 15px !important;
  display: inline-block;
  width: 25px;
  height: 25px;
  background-size: cover;
}
#sidebar.activexyz .info {
  background-image: url(../../Images/info.svg);
  background-repeat: no-repeat;
  margin: 15px !important;
  display: inline-block;
  width: 25px;
  height: 25px;
  background-size: cover;
}
#sidebar.activexyz .upload_doc {
  background-image: url(../../Images/Document.svg);
  background-repeat: no-repeat;
  margin: 15px !important;
  display: inline-block;
  width: 20px;
  height: 25px;
  background-size: cover;
}
#sidebar.activexyz .dash_document {
  background-image: url(../../Images/dashboard.svg);
  background-repeat: no-repeat;
  margin: 15px !important;
  display: inline-block;
  width: 25px;
  height: 25px;
  background-size: cover;
}
#sidebar.activexyz .upload_document {
  background-image: url(../../Images/upload.svg);
  background-repeat: no-repeat;
  margin: 15px !important;
  display: inline-block;
  width: 25px;
  height: 25px;
  background-size: contain;
}
#sidebar.activexyz .open-cases {
  background-image: url(../../Images/open-cases.svg);
  background-repeat: no-repeat;
  margin: 15px !important;
  display: inline-block;
  width: 25px;
  height: 25px;
  background-size: contain;
}
#sideNavBottom {
  /* margin-top: 170px; */
  margin-bottom: 0px !important;
  bottom: 0px !important;
  position: fixed;
  width: inherit;
}

#sideNavBottom li {
  margin-bottom: 20px;
}

#sidebar.activexyz .Information {
  /* background-image: url(../../Images/information.svg); */
  background-repeat: no-repeat;
  width: 26px;
  height: 31px;
  margin: 15px !important;
  display: inline-block;
}

/* #sidebar.activexyz .Logout {
  background-image: url(../../Images/logout.svg);
  background-repeat: no-repeat;
  width: 26px;
  height: 31px;
  margin: 15px !important;
  display: inline-block;
} */
#sidebar .Logout {
  background-color: #0f5755;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin: 0 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: ProximaNovaB !important;
}
.dialogLogo {
  background-color: #0f5755;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  font-weight: bolder;
}
.MuiBackdrop-root {
  background-color: #0d1e1ed1 !important;
}
/* Custom Dialog Styles -- start */
.custom-dialog-container-info > .MuiDialog-container {
  outline: 0;
  position: absolute;
  bottom: 80px !important;
  left: 70px;
  height: 100%;
}
.custom-dialog-container > .MuiDialog-container {
  outline: 0;
  position: absolute;
  bottom: 10px;
  left: 70px;
  height: 100%;
}

.custom-dialog-container > .MuiDialog-scrollPaper {
  display: flex;
  align-items: flex-end !important;
}
.custom-dialog-container-info .MuiDialog-paperWidthSm {
  border-radius: 5px !important;
  border: 2px solid #048c88;
  padding: 0px !important;
}

.custom-dialog-container .MuiDialog-paperWidthSm {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 16px 24px;
  border-radius: 11px;
}
.custom-dialog-container .MuiDialogContent-root {
  padding: 0 40px !important;
}
.custom-dialog-container .MuiTypography-body1 {
  font-size: 10px !important;
  font-family: ProximaNovaS !important;
}
.custom-dialog-container .MuiDialogTitle-root {
  padding: 0 !important;
}
.custom-dialog-container .MuiDialogTitle-root .MuiTypography-root {
  font-family: ProximaNovaB !important;
}
.custom-dialog-container .MuiDialogActions-root {
  display: flex;
  padding: 0px !important;
  justify-content: flex-start !important;
  width: 100%;
}
.custom-dialog-container .MuiDialogActions-root > Button {
  padding: 8px 0 !important;
  font-size: 12px;
  background: transparent !important;
  font-family: ProximaNovaB !important;
  width: 100%;
}
.dialogFooter {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  width: 100%;
}
.dialogFooter > a {
  font-size: 8px;
  color: #676a6a;
}

/* Custom Dialog style --end */
.MuiTooltip-tooltip {
  background: #15a19d !important;
  box-shadow: 0px 4px 16px #43828040 !important;
  padding: 0.5rem 1rem !important;
  /* border-radius: 20px 20px 20px 20px !important; */
  margin: 0 0.5rem !important;
  font-family: ProximaNovaR !important;
  font-size: 12px !important;
}
#sidebar #collapseIcon {
  bottom: 0;
  background-color: #005a56;
  height: 45px;
  width: 190px;
  cursor: pointer;
  /* background-color: #BFF2F1; */
  transition: 0.3s;
}

#sidebar .openIcon {
  color: white;
  font-size: 25px;
  font-weight: bold;
  padding-top: 5px !important;
  padding-bottom: 3px !important;
  /* padding-left: 150px; */
  position: fixed;
  margin-left: 150px;
  /* transition: 0.1s; */
  transform: rotate(180deg);
}

#sidebar.activexyz #collapseIcon {
  bottom: 0;
  background-color: #005a56;
  height: 45px;
  width: 60px;
  cursor: pointer;
}

#sidebar.activexyz .openIcon {
  color: white;
  font-size: 25px;
  font-weight: bold;
  padding-top: 5px;

  transform: none;
  /* transition: 1s; */
  position: fixed;
  margin-left: 23px !important;
}

#sidebar.activexyz #tool {
  display: none;
}

.styles_modal__gNwvD {
  min-width: 500px;
  min-height: 300px;
  margin: 300px 700px;
}

.noDisp {
  display: none !important;
}
.devinfo {
  display: flex;
  font-family: ProximaNovaR;
  font-size: 14px;
  color: #48454a;
  padding: 3px 12px;
}
.devinfo:hover {
  background-color: #d1f4f0;
  cursor: pointer;
}
.devinfo img {
  margin-right: 8px;
}
/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */
.active {
  background-color: #0f5755;
  border-left: solid 3px #48c8c5;
}
@media (max-width: 768px) {
  #sidebar {
    min-width: 80px;
    max-width: 80px;
    text-align: center;
    margin-left: -80px !important;
  }
  .dropdown-toggle::after {
    top: auto;
    bottom: 10px;
    right: 50%;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
  }
  #sidebar.activexyz {
    margin-left: 0 !important;
    /* background-color: #fff; */
  }
  #sidebar .sidebar-header h3,
  #sidebar .CTAs {
    display: none;
  }
  #sidebar .sidebar-header strong {
    display: block;
  }
  #sidebar ul li a span {
    font-size: 0.85em;
  }
  #sidebar ul li a i {
    margin-right: 0;
    display: block;
  }
  #sidebar ul li a i {
    font-size: 1.3em;
  }
  #sidebar {
    margin-left: 0;
  }
  #sidebarCollapse span {
    display: none;
  }
}
